/* eslint-disable import/no-named-as-default */
import React, { Component, Suspense } from "react";
import { Switch, Route, Redirect } from "react-router-dom";

import Spinner from "./shared/Spinner";

// <<<<<<<<<<< 홈 >>>>>>>>>>>>
const Dashboard = React.lazy(() => import("./dashboard/Dashboard"));
const PixelroIn = React.lazy(() => import("./login/PixelroIn"));

// <<<<<<<<<<< 등록관리 >>>>>>>>>>>>
// 등록관리 - 상점목록
const StoreTable = React.lazy(() => import("./registered-store/stores/StoreTable"));
const StoreDetail = React.lazy(() => import("./registered-store/stores/StoreDetail"));
const StoreEdit = React.lazy(() => import("./registered-store/stores/StoreEdit"));
const StoreCreate = React.lazy(() => import("./registered-store/stores/StoreCreate"));
// 등록관리 - 상품목록
const ProductTable = React.lazy(() => import("./registered-store/products/ProductTable"));
const ProductDetail = React.lazy(() => import("./registered-store/products/ProductDetail"));
const ProductEdit = React.lazy(() => import("./registered-store/products/ProductEdit"));
const ProductCreate = React.lazy(() => import("./registered-store/products/ProductCreate"));
// 등록관리 - 상품등록요청
const ProductRegisterTable = React.lazy(() => import("./registered-store/register/ProductRegisterTable"));
const ProductRegisterDetail = React.lazy(() => import("./registered-store/register/ProductRegisterDetail"));

// <<<<<<<<<<< 미등록관리 >>>>>>>>>>>>
// 미등록관리 - 신청접수목록
const RequestTable = React.lazy(() => import("./unregistered-store/request/RequestTable"));
const RequestDetail = React.lazy(() => import("./unregistered-store/request/RequestDetail"));

// <<<<<<<<<<< 앱멤버관리 >>>>>>>>>>>>
// 앱멤버관리 - 멤버목록
const UserTable = React.lazy(() => import("./app-users/users/UserTable"));
const UserDetail = React.lazy(() => import("./app-users/users/UserDetail"));
const UserEdit = React.lazy(() => import("./app-users/users/UserEdit"));
// 앱멤버관리 - 픽업예약
const PickupReservationTable = React.lazy(() => import("./app-users/pickupReservation/PickupReservationTable"));
const PickupReservationDetail = React.lazy(() => import("./app-users/pickupReservation/PickupReservationDetail"));
// 앱멤버관리 - 렌즈예약
const LensReservationTable = React.lazy(() => import("./app-users/lensReservation/LensReservationTable"));
const LensReservationDetail = React.lazy(() => import("./app-users/lensReservation/LensReservationDetail"));

// <<<<<<<<<<< 앱 푸쉬 메세지 >>>>>>>>>>>>
const AppPushMessage = React.lazy(() => import("./app-push-message/AppPushMessage"));

// <<<<<<<<<<< 실적관리 >>>>>>>>>>>>
// 회원사관리 - 회원사목록
const MemberTable = React.lazy(() => import("./members/MemberTable"));
const MemberDetail = React.lazy(() => import("./members/MemberDetail"));
const MemberEdit = React.lazy(() => import("./members/MemberEdit"));
const MemberCreate = React.lazy(() => import("./members/MemberCreate"));
const EmployeeCreate = React.lazy(() => import("./members/EmployeeCreate"));
// 회원사관리 - 회원사수익신청목록
const SettlemenRequestTable = React.lazy(() => import("./members/settlementRequest/SettlementRequestTable"));
const SettlementRequestDetail = React.lazy(() => import("./members/settlementRequest/SettlementRequestDetail"));
const SettlementProductList = React.lazy(() => import("./members/settlementRequest/SettlementProductList"));
// 회원사관리 - 회원사세금계산서현황
const SettlemenTable = React.lazy(() => import("./members/settlement/SettlementTable"));
const SettlementDetail = React.lazy(() => import("./members/settlement/SettlementDetail"));
// 회원사관리 - 가맹취소내역
const StoreWithdrawTable = React.lazy(() => import("./members/withdraw/StoreWithdrawTable"));
// 회원사관리 - 메세지 충전 신청
const MessageTable = React.lazy(() => import("./members/message/MessageTable"));

// <<<<<<<<<<< 실적관리 >>>>>>>>>>>>
// 실적관리 - 기간별매출조회
const DateSales = React.lazy(() => import("./sales/date/DateSales"));
// 실적관리 - 상점별매출조회
const StoreSales = React.lazy(() => import("./sales/store/StoreSales"));
const StoreSalesDetail = React.lazy(() => import("./sales/store/StoreSalesDetail"));
// 실적관리 - 베스트 셀러
const BestSales = React.lazy(() => import("./sales/best/BestSales"));
// 실적관리 - 쿠폰
const CouponTable = React.lazy(() => import("./sales/coupon/CouponTable"));
const CouponDetail = React.lazy(() => import("./sales/coupon/CouponDetail"));
const CouponCreate = React.lazy(() => import("./sales/coupon/CouponCreate"));
const CouponEdit = React.lazy(() => import("./sales/coupon/CouponEdit"));

// <<<<<<<<<<< 주문관리 >>>>>>>>>>>>
// 주문관리 - 배송완료처리
const DeliveryCheckTable = React.lazy(() => import("./orders/delivery-check/DeliveryCheckTable"));
// 주문관리 - 구매확정처리
const ConfirmCheckTable = React.lazy(() => import("./orders/confirm-check/ConfirmCheckTable"));
// 주문관리 - 교환완료처리
const ChangeCheckTable = React.lazy(() => import("./orders/change-check/ChangeCheckTable"));

// <<<<<<<<<<< 사이트꾸미기 >>>>>>>>>>>>
// 사이트꾸미기 - 배너
const Banner = React.lazy(() => import("./banners/Banner"));
const BannerCreate = React.lazy(() => import("./banners/BannerCreate"));
const BannerDetail = React.lazy(() => import("./banners/BannerDetail"));
const BannerEdit = React.lazy(() => import("./banners/BannerEdit"));
// 사이트꾸미기 - 추천상품
const RecommendProduct = React.lazy(() => import("./banners/RecommendProduct"));
const RecommendProductCreate = React.lazy(() => import("./banners/RecommendProductCreate"));
const RecommendProductDetail = React.lazy(() => import("./banners/RecommendProductDetail"));
const RecommendProductEdit = React.lazy(() => import("./banners/RecommendProductEdit"));

// <<<<<<<<<<< 게시판관리 >>>>>>>>>>>>
// 게시판관리 - 매거진
const MagazineTable = React.lazy(() => import("./notice-board/magazine/MagazineTable"));
const MagazineDetail = React.lazy(() => import("./notice-board/magazine/MagazineDetail"));
const MagazineEdit = React.lazy(() => import("./notice-board/magazine/MagazineEdit"));
const MagazineCreate = React.lazy(() => import("./notice-board/magazine/MagazineCreate"));
// 게시판관리 - 이벤트
const EventTable = React.lazy(() => import("./notice-board/event/EventTable"));
const EventDetail = React.lazy(() => import("./notice-board/event/EventDetail"));
const EventEdit = React.lazy(() => import("./notice-board/event/EventEdit"));
const EventCreate = React.lazy(() => import("./notice-board/event/EventCreate"));

// <<<<<<<<<<< 고객센터 >>>>>>>>>>>>
// 고객센터 - 공지사항
const NoticeTable = React.lazy(() => import("./service-center/notice/NoticeTable"));
const NoticeDetail = React.lazy(() => import("./service-center/notice/NoticeDetail"));
const NoticeEdit = React.lazy(() => import("./service-center/notice/NoticeEdit"));
const NoticeCreate = React.lazy(() => import("./service-center/notice/NoticeCreate"));
// 고객센터 - 이용약관
const ProvisionTable = React.lazy(() => import("./service-center/provision/ProvisionTable"));
const ProvisionDetail = React.lazy(() => import("./service-center/provision/ProvisionDetail"));
const ProvisionEdit = React.lazy(() => import("./service-center/provision/ProvisionEdit"));
const ProvisionCreate = React.lazy(() => import("./service-center/provision/ProvisionCreate"));
// 고객센터 - 운영정책
const OperationTable = React.lazy(() => import("./service-center/operation/OperationTable"));
const OperationDetail = React.lazy(() => import("./service-center/operation/OperationDetail"));
const OperationEdit = React.lazy(() => import("./service-center/operation/OperationEdit"));
const OperationCreate = React.lazy(() => import("./service-center/operation/OperationCreate"));
// 고객센터 - 멤버FAQ
const AppUserFAQTable = React.lazy(() => import("./service-center/app-user-faq/AppUserFAQTable"));
const AppUserFAQDetail = React.lazy(() => import("./service-center/app-user-faq/AppUserFAQDetail"));
const AppUserFAQEdit = React.lazy(() => import("./service-center/app-user-faq/AppUserFAQEdit"));
const AppUserFAQCreate = React.lazy(() => import("./service-center/app-user-faq/AppUserFAQCreate"));
// 고객센터 - 회원사FAQ
const MemberFAQTable = React.lazy(() => import("./service-center/member-faq/MemberFAQTable"));
const MemberFAQDetail = React.lazy(() => import("./service-center/member-faq/MemberFAQDetail"));
const MemberFAQEdit = React.lazy(() => import("./service-center/member-faq/MemberFAQEdit"));
const MemberFAQCreate = React.lazy(() => import("./service-center/member-faq/MemberFAQCreate"));
// 고객센터 - 회원사QNA
const MemberQnaTable = React.lazy(() => import("./service-center/member-qna/MemberQnaTable"));
const MemberQnaDetail = React.lazy(() => import("./service-center/member-qna/MemberQnaDetail"));
// 고객센터 - 멤버QNA
const AppUserQnaTable = React.lazy(() => import("./service-center/app-user-qna/AppUserQnaTable"));
const AppUserQnaDetail = React.lazy(() => import("./service-center/app-user-qna/AppUserQnaDetail"));

// <<<<<<<<<<< 관리자 >>>>>>>>>>>>
// 관리자 - 관리자목록
const PixelroTable = React.lazy(() => import("./pixelro/PixelroTable"));
const PixelroEdit = React.lazy(() => import("./pixelro/PixelroEdit"));
const PixelroCreate = React.lazy(() => import("./pixelro/PixelroCreate"));

class AppRoutes extends Component {
  render() {
    return (
      <Suspense fallback={<Spinner />}>
        <Switch>
          <Route exact path="/" component={Dashboard} />
          <Route exact path="/dashboard" component={Dashboard} />
          <Route exact path="/adminIn" component={PixelroIn} />
          {/* 등록관리 -  */}
          {/* 상점 목록 */}
          <Route path="/registered-store/store-table" component={StoreTable} />
          <Route path="/registered-store/store-detail/:id" component={StoreDetail} />
          <Route path="/registered-store/store-edit/:id" component={StoreEdit} />
          <Route path="/registered-store/store-create/:id/:memberId/:defaultStoreId" component={StoreCreate} />
          {/* 상품 목록 */}
          <Route path="/registered-store/products-table" component={ProductTable} />
          <Route path="/registered-store/product-detail/:id" component={ProductDetail} />
          <Route path="/registered-store/product-edit/:id" component={ProductEdit} />
          <Route path="/registered-store/product-create/:id" component={ProductCreate} />
          {/* 상품 등록 요청 */}
          <Route path="/registered-store/product-register-table" component={ProductRegisterTable} />
          <Route path="/registered-store/product-register-detail/:id" component={ProductRegisterDetail} />
          {/* - 등록관리 */}
          <Route path="/unregistered-store/request-table" component={RequestTable} />
          <Route path="/unregistered-store/request-detail/:id" component={RequestDetail} />
          {/* 앱 멤버 관리 */}
          <Route path="/app-users/user-table" component={UserTable} />
          <Route path="/app-users/user-detail/:id" component={UserDetail} />
          <Route path="/app-users/user-edit/:id" component={UserEdit} />
          <Route path="/app-users/pickup-reservation-table" component={PickupReservationTable} />
          <Route path="/app-users/lens-reservation-table" component={LensReservationTable} />
          <Route path="/app-users/pickup-reservation-detail/:id" component={PickupReservationDetail} />
          <Route path="/app-users/lens-reservation-detail/:id" component={LensReservationDetail} />
          {/* 앱 푸쉬 메세지 */}
          <Route path="/app-push-message" component={AppPushMessage} />
          {/* 회원사 관리 */}
          <Route path="/members/member-table" component={MemberTable} />
          <Route path="/members/member-detail/:id" component={MemberDetail} />
          <Route path="/members/member-edit/:id" component={MemberEdit} />
          <Route path="/members/member-create" component={MemberCreate} />
          <Route path="/members/employee-create/:id" component={EmployeeCreate} />
          {/* 수익 신청 목록 */}
          <Route path="/members/settlement-request-table" component={SettlemenRequestTable} />
          <Route path="/members/settlement-request-detail/:id" component={SettlementRequestDetail} />
          <Route path="/members/settlement-product-list/:id" component={SettlementProductList} />
          <Route path="/members/settlement-table" component={SettlemenTable} />
          <Route path="/members/settlement-detail/:id" component={SettlementDetail} />
          <Route path="/members/store-withdraw-table" component={StoreWithdrawTable} />
          <Route path="/members/message-table" component={MessageTable} />
          {/* 실적 관리 */}
          <Route path="/sales/date-sales" component={DateSales} />
          <Route path="/sales/store-sales" component={StoreSales} />
          <Route path="/sales/store-sales-detail/:id" component={StoreSalesDetail} />
          <Route path="/sales/best-sales" component={BestSales} />
          <Route path="/sales/coupon" component={CouponTable} />
          <Route path="/sales/coupon-detail/:id" component={CouponDetail} />
          <Route path="/sales/coupon-create" component={CouponCreate} />
          <Route path="/sales/coupon-edit/:id" component={CouponEdit} />
          {/* 주문 관리 */}
          <Route path="/orders/delivery-check" component={DeliveryCheckTable} />
          <Route path="/orders/confirm-check" component={ConfirmCheckTable} />
          <Route path="/orders/change-check" component={ChangeCheckTable} />
          {/* 배너 */}
          <Route path="/banners/banner" component={Banner} />
          <Route path="/banners/banner-create" component={BannerCreate} />
          <Route path="/banners/banner-detail/:id" component={BannerDetail} />
          <Route path="/banners/banner-edit/:id" component={BannerEdit} />
          {/* 추천 상품 & 장소 */}
          <Route path="/banners/recommend-product" component={RecommendProduct} />
          <Route path="/banners/recommend-product-create" component={RecommendProductCreate} />
          <Route path="/banners/recommend-product-detail/:id" component={RecommendProductDetail} />
          <Route path="/banners/recommend-product-edit/:id" component={RecommendProductEdit} />
          {/* 공지사항 */}
          <Route path="/service-center/notice-table" component={NoticeTable} />
          <Route path="/service-center/notice-detail/:id" component={NoticeDetail} />
          <Route path="/service-center/notice-edit/:id" component={NoticeEdit} />
          <Route path="/service-center/notice-create" component={NoticeCreate} />
          {/* 이용약관 */}
          <Route path="/service-center/provision-table" component={ProvisionTable} />
          <Route path="/service-center/provision-detail/:id" component={ProvisionDetail} />
          <Route path="/service-center/provision-edit/:id" component={ProvisionEdit} />
          <Route path="/service-center/provision-create" component={ProvisionCreate} />
          {/* 운영정책 */}
          <Route path="/service-center/operation-table" component={OperationTable} />
          <Route path="/service-center/operation-detail/:id" component={OperationDetail} />
          <Route path="/service-center/operation-edit/:id" component={OperationEdit} />
          <Route path="/service-center/operation-create" component={OperationCreate} />
          {/* 멤버 FAQ (app user) */}
          <Route path="/service-center/app-user-faq-table" component={AppUserFAQTable} />
          <Route path="/service-center/app-user-faq-detail/:id" component={AppUserFAQDetail} />
          <Route path="/service-center/app-user-faq-edit/:id" component={AppUserFAQEdit} />
          <Route path="/service-center/app-user-faq-create" component={AppUserFAQCreate} />
          {/* 회원사 FAQ */}
          <Route path="/service-center/member-faq-table" component={MemberFAQTable} />
          <Route path="/service-center/member-faq-detail/:id" component={MemberFAQDetail} />
          <Route path="/service-center/member-faq-edit/:id" component={MemberFAQEdit} />
          <Route path="/service-center/member-faq-create" component={MemberFAQCreate} />
          {/* QNA */}
          <Route path="/service-center/member-qna-table" component={MemberQnaTable} />
          <Route path="/service-center/member-qna-detail/:id" component={MemberQnaDetail} />
          <Route path="/service-center/app-user-qna-table" component={AppUserQnaTable} />
          <Route path="/service-center/app-user-qna-detail/:id" component={AppUserQnaDetail} />
          {/* 이벤트 */}
          <Route path="/notice-board/event-table" component={EventTable} />
          <Route path="/notice-board/event-detail/:id" component={EventDetail} />
          <Route path="/notice-board/event-edit/:id" component={EventEdit} />
          <Route path="/notice-board/event-create" component={EventCreate} />
          {/* 매거진 */}
          <Route path="/notice-board/magazine-table" component={MagazineTable} />
          <Route path="/notice-board/magazine-detail/:id" component={MagazineDetail} />
          <Route path="/notice-board/magazine-edit/:id" component={MagazineEdit} />
          <Route path="/notice-board/magazine-create" component={MagazineCreate} />
          {/* 픽셀로 관리자 */}
          <Route path="/pixelro/pixelro-table" component={PixelroTable} />
          <Route path="/pixelro/pixelro-edit/:id" component={PixelroEdit} />
          <Route path="/pixelro/pixelro-create" component={PixelroCreate} />
          <Redirect to="/dashboard" />
        </Switch>
      </Suspense>
    );
  }
}

export default AppRoutes;
