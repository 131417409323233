/* eslint-disable react/sort-comp */
/* eslint-disable react/state-in-constructor */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/destructuring-assignment */
/* eslint-disable no-nested-ternary */
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";
import { Collapse } from "react-bootstrap";

class Sidebar extends Component {
  state = {};

  toggleMenuState(menuState) {
    if (this.state[menuState]) {
      this.setState({ [menuState]: false });
    } else if (Object.keys(this.state).length === 0) {
      this.setState({ [menuState]: true });
    } else {
      Object.keys(this.state).forEach((i) => {
        this.setState({ [i]: false });
      });
      this.setState({ [menuState]: true });
    }
  }

  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      this.onRouteChanged();
    }
  }

  onRouteChanged() {
    document.querySelector("#sidebar").classList.remove("active");
    Object.keys(this.state).forEach((i) => {
      this.setState({ [i]: false });
    });

    const dropdownPaths = [
      { path: "/orders", state: "ordersMenuOpen" },
      { path: "/sales", state: "salesMenuOpen" },
      { path: "/products", state: "basicUiMenuOpen" },
      { path: "/orders", state: "advancedUiMenuOpen" },
      { path: "/delivery", state: "formElementsMenuOpen" },
      { path: "/tables", state: "tablesMenuOpen" },
      { path: "/maps", state: "mapsMenuOpen" },
      { path: "/customers", state: "customersMenuOpen" },
      { path: "/app-customers", state: "appCustomersMenuOpen" },
      { path: "/icons", state: "iconsMenuOpen" },
      { path: "/cms", state: "chartsMenuOpen" },
      { path: "/user-pages", state: "userPagesMenuOpen" },
      { path: "/error-pages", state: "errorPagesMenuOpen" },
      { path: "/general-pages", state: "generalPagesMenuOpen" },
      { path: "/registered-store", state: "registeredMenuOpen" },
      { path: "/unregistered-store", state: "unRegisteredMenuOpen" },
      { path: "/app-users", state: "appUsersMenuOpen" },
      { path: "/members", state: "membersMenuOpen" },
      { path: "/banners", state: "siteDecorateMenuOpen" },
      { path: "/banner", state: "siteDecorateMenuOpen" },
      { path: "/service-center", state: "serviceCenterMenuOpen" },
      { path: "/pixelro-table", state: "pixelroMenuOpen" },
      { path: "/notice-board", state: "noticeBoardMenuOpen" },
    ];

    dropdownPaths.forEach((obj) => {
      if (this.isPathActive(obj.path)) {
        this.setState({ [obj.state]: true });
      }
    });
  }

  render() {
    return (
      <nav className="sidebar sidebar-offcanvas" id="sidebar">
        <ul className="nav">
          <li className={this.isPathActive("/dashboard") ? "nav-item active" : "nav-item"}>
            <Link className="nav-link" to="/dashboard">
              <i className="ti-home menu-icon" />
              <span className="menu-title">홈</span>
            </Link>
          </li>
          <hr />
          {/* 등록 관리 */}
          <li className={this.isPathActive("/registered-store") ? "nav-item active" : "nav-item"}>
            <div
              className={this.state.registeredMenuOpen ? "nav-link menu-expanded" : "nav-link"}
              onClick={() => this.toggleMenuState("registeredMenuOpen")}
              data-toggle="collapse"
            >
              <i className="ti-agenda menu-icon" />
              <span className="menu-title">등록관리</span>
            </div>
            <Collapse in={this.state.registeredMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={this.isPathActive("/registered-store/store-table") ? "nav-link active" : "nav-link"}
                    to="/registered-store/store-table"
                  >
                    상점 목록
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={this.isPathActive("/registered-store/products-table") ? "nav-link active" : "nav-link"}
                    to="/registered-store/products-table"
                  >
                    상품 목록
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={this.isPathActive("/registered-store/product-register-table") ? "nav-link active" : "nav-link"}
                    to="/registered-store/product-register-table"
                  >
                    상품 등록 요청
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          {/* 미등록 관리 */}
          <li className={this.isPathActive("/unregistered-store") ? "nav-item active" : "nav-item"}>
            <div
              className={this.state.unRegisteredMenuOpen ? "nav-link menu-expanded" : "nav-link"}
              onClick={() => this.toggleMenuState("unRegisteredMenuOpen")}
              data-toggle="collapse"
            >
              <i className="ti-receipt menu-icon" />
              <span className="menu-title">미등록 관리</span>
            </div>
            <Collapse in={this.state.unRegisteredMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={this.isPathActive("/unregistered-store/request-table") ? "nav-link active" : "nav-link"}
                    to="/unregistered-store/request-table"
                  >
                    신청 접수 목록
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          {/* 앱 멤버 관리 */}
          <li className={this.isPathActive("/app-users") ? "nav-item active" : "nav-item"}>
            <div
              className={this.state.appUsersMenuOpen ? "nav-link menu-expanded" : "nav-link"}
              onClick={() => this.toggleMenuState("appUsersMenuOpen")}
              data-toggle="collapse"
            >
              <i className="ti-mobile menu-icon" />
              <span className="menu-title">앱 멤버 관리</span>
            </div>
            <Collapse in={this.state.appUsersMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link className={this.isPathActive("/app-users/user-table") ? "nav-link active" : "nav-link"} to="/app-users/user-table">
                    멤버 목록
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={this.isPathActive("/app-users/pickup-reservation-table") ? "nav-link active" : "nav-link"}
                    to="/app-users/pickup-reservation-table"
                  >
                    픽업 예약
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={this.isPathActive("/app-users/lens-reservation-table") ? "nav-link active" : "nav-link"}
                    to="/app-users/lens-reservation-table"
                  >
                    렌즈 예약
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          {/* 앱 푸쉬 메세지 */}
          <li className={this.isPathActive("/app-push-message") ? "nav-item active" : "nav-item"}>
            <Link className="nav-link" to="/app-push-message">
              <i className="ti-light-bulb menu-icon" />
              <span className="menu-title">앱 푸쉬 메세지</span>
            </Link>
          </li>
          {/* 회원사 관리 */}
          <li className={this.isPathActive("/members") ? "nav-item active" : "nav-item"}>
            <div
              className={this.state.membersMenuOpen ? "nav-link menu-expanded" : "nav-link"}
              onClick={() => this.toggleMenuState("membersMenuOpen")}
              data-toggle="collapse"
            >
              <i className="ti-face-smile menu-icon" />
              <span className="menu-title">회원사 관리</span>
            </div>
            <Collapse in={this.state.membersMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link className={this.isPathActive("/members/member-table") ? "nav-link active" : "nav-link"} to="/members/member-table">
                    회원사 목록
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={this.isPathActive("/members/settlement-request-table") ? "nav-link active" : "nav-link"}
                    to="/members/settlement-request-table"
                  >
                    회원사 수익 신청 목록
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={this.isPathActive("/members/settlement-table") ? "nav-link active" : "nav-link"}
                    to="/members/settlement-table"
                  >
                    회원사 세금 계산서 현황
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={this.isPathActive("/members/store-withdraw-table") ? "nav-link active" : "nav-link"}
                    to="/members/store-withdraw-table"
                  >
                    가맹취소내역
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={this.isPathActive("/members/message-table") ? "nav-link active" : "nav-link"}
                    to="/members/message-table"
                  >
                    메세지 충전 신청
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          {/* 실적관리 */}
          <li className={this.isPathActive("/sales") ? "nav-item active" : "nav-item"}>
            <div
              className={this.state.salesMenuOpen ? "nav-link menu-expanded" : "nav-link"}
              onClick={() => this.toggleMenuState("salesMenuOpen")}
              data-toggle="collapse"
            >
              <i className="ti-money menu-icon" />
              <span className="menu-title">실적 관리</span>
            </div>
            <Collapse in={this.state.salesMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link className={this.isPathActive("/sales/date-sales") ? "nav-link active" : "nav-link"} to="/sales/date-sales">
                    기간별 매출 조회
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={this.isPathActive("/sales/store-sales") ? "nav-link active" : "nav-link"} to="/sales/store-sales">
                    상점별 매출 조회
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={this.isPathActive("/sales/best-sales") ? "nav-link active" : "nav-link"} to="/sales/best-sales">
                    베스트 셀러
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={this.isPathActive("/sales/coupon") ? "nav-link active" : "nav-link"} to="/sales/coupon">
                    쿠폰
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          {/* 주문관리 */}
          <li className={this.isPathActive("/orders") ? "nav-item active" : "nav-item"}>
            <div
              className={this.state.ordersMenuOpen ? "nav-link menu-expanded" : "nav-link"}
              onClick={() => this.toggleMenuState("ordersMenuOpen")}
              data-toggle="collapse"
            >
              <i className="ti-package menu-icon" />
              <span className="menu-title">주문 관리</span>
            </div>
            <Collapse in={this.state.ordersMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={this.isPathActive("/orders/delivery-check") ? "nav-link active" : "nav-link"}
                    to="/orders/delivery-check"
                  >
                    배송완료처리
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={this.isPathActive("/orders/confirm-check") ? "nav-link active" : "nav-link"} to="/orders/confirm-check">
                    구매확정처리
                  </Link>
                </li>
                <li className="nav-item">
                  <Link className={this.isPathActive("/orders/change-check") ? "nav-link active" : "nav-link"} to="/orders/change-check">
                    교환완료처리
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          <li className={this.isPathActive("/banners") ? "nav-item active" : this.isPathActive("banner") ? "nav-item active" : "nav-item"}>
            <div
              className={this.state.siteDecorateMenuOpen ? "nav-link menu-expanded" : "nav-link"}
              onClick={() => this.toggleMenuState("siteDecorateMenuOpen")}
              data-toggle="collapse"
            >
              <i className="ti-image menu-icon" />
              <span className="menu-title">사이트 꾸미기</span>
            </div>
            <Collapse in={this.state.siteDecorateMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link className={this.isPathActive("/banners/banner") ? "nav-link active" : "nav-link"} to="/banners/banner">
                    배너
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={this.isPathActive("/banners/recommend-product") ? "nav-link active" : "nav-link"}
                    to="/banners/recommend-product"
                  >
                    추천 상품
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          <li className={this.isPathActive("/notice-board") ? "nav-item active" : "nav-item"}>
            <div
              className={this.state.noticeBoardMenuOpen ? "nav-link menu-expanded" : "nav-link"}
              onClick={() => this.toggleMenuState("noticeBoardMenuOpen")}
              data-toggle="collapse"
            >
              <i className="ti-gift menu-icon" />
              <span className="menu-title">게시판 관리</span>
            </div>
            <Collapse in={this.state.noticeBoardMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={this.isPathActive("/notice-board/magazine-table") ? "nav-link active" : "nav-link"}
                    to="/notice-board/magazine-table"
                  >
                    매거진
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={this.isPathActive("/notice-board/event-table") ? "nav-link active" : "nav-link"}
                    to="/notice-board/event-table"
                  >
                    이벤트
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          {/* 고객센터 */}
          <li className={this.isPathActive("/service-center") ? "nav-item active" : "nav-item"}>
            <div
              className={this.state.serviceCenterMenuOpen ? "nav-link menu-expanded" : "nav-link"}
              onClick={() => this.toggleMenuState("serviceCenterMenuOpen")}
              data-toggle="collapse"
            >
              <i className="ti-comment-alt menu-icon" />
              <span className="menu-title">고객센터</span>
            </div>
            <Collapse in={this.state.serviceCenterMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={this.isPathActive("/service-center/notice-table") ? "nav-link active" : "nav-link"}
                    to="/service-center/notice-table"
                  >
                    공지사항
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={this.isPathActive("/service-center/app-user-faq-table") ? "nav-link active" : "nav-link"}
                    to="/service-center/app-user-faq-table"
                  >
                    멤버 FAQ
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={this.isPathActive("/service-center/member-faq-table") ? "nav-link active" : "nav-link"}
                    to="/service-center/member-faq-table"
                  >
                    회원사 FAQ
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={this.isPathActive("/service-center/app-user-qna-table") ? "nav-link active" : "nav-link"}
                    to="/service-center/app-user-qna-table"
                  >
                    멤버 QNA (1:1문의)
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={this.isPathActive("/service-center/member-qna-table") ? "nav-link active" : "nav-link"}
                    to="/service-center/member-qna-table"
                  >
                    회원사 QNA (1:1문의)
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={this.isPathActive("/service-center/provision-table") ? "nav-link active" : "nav-link"}
                    to="/service-center/provision-table"
                  >
                    약관 관리
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    className={this.isPathActive("/service-center/operation-table") ? "nav-link active" : "nav-link"}
                    to="/service-center/operation-table"
                  >
                    운영 정책
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
          {/* 관리자 목록 */}
          <li className={this.isPathActive("/pixelro") ? "nav-item active" : "nav-item"}>
            <div
              className={this.state.pixelroMenuOpen ? "nav-link menu-expanded" : "nav-link"}
              onClick={() => this.toggleMenuState("pixelroMenuOpen")}
              data-toggle="collapse"
            >
              <i className="ti-user menu-icon" />
              <span className="menu-title">관리자</span>
            </div>
            <Collapse in={this.state.pixelroMenuOpen}>
              <ul className="nav flex-column sub-menu">
                <li className="nav-item">
                  <Link
                    className={this.isPathActive("/pixelro/pixelro-table") ? "nav-link active" : "nav-link"}
                    to="/pixelro/pixelro-table"
                  >
                    관리자 목록
                  </Link>
                </li>
              </ul>
            </Collapse>
          </li>
        </ul>
      </nav>
    );
  }

  isPathActive(path) {
    return this.props.location.pathname.startsWith(path);
  }

  componentDidMount() {
    this.onRouteChanged();
    const body = document.querySelector("body");
    document.querySelectorAll(".sidebar .nav-item").forEach((el) => {
      el.addEventListener("mouseover", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.add("hover-open");
        }
      });
      el.addEventListener("mouseout", () => {
        if (body.classList.contains("sidebar-icon-only")) {
          el.classList.remove("hover-open");
        }
      });
    });
  }
}

export default withRouter(Sidebar);
