import ApolloClient from "apollo-client";
import { setContext } from "apollo-link-context";
import { InMemoryCache } from "apollo-cache-inmemory";
import { createUploadLink } from "apollo-upload-client";

console.log("process.env.NODE_ENV = ", process.env.NODE_ENV);
const link =
  process.env.NODE_ENV === "production"
    ? createUploadLink({ uri: "https://nenoonsapi.du.r.appspot.com/graphql" })
    : createUploadLink({ uri: "http://localhost:4002/graphql" });

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem("token");
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const client = new ApolloClient({
  link: authLink.concat(link),
  cache: new InMemoryCache(),
  onError: ({ networkError, graphQLErrors }) => {
    console.log("graphQLErrors", graphQLErrors);
    console.log("networkError", networkError);
  },
});

export default client;
