/* eslint-disable class-methods-use-this */
import React from "react";
import { Dropdown } from "react-bootstrap";
import { useHistory } from "react-router-dom";
import useSettings from "stores/settings";

const Navbar = () => {
  const toggleOffcanvas = () => {
    document.querySelector(".sidebar-offcanvas").classList.toggle("active");
  };

  const settings = useSettings();
  const history = useHistory();

  const handleLogOut = () => {
    settings.isLogin = false;
    settings.token = "";
    window.localStorage.setItem("token", "");
    history.push("/adminIn");
  };

  return (
    <nav className="navbar default-layout-navbar col-lg-12 col-12 p-0 fixed-top d-flex flex-row">
      <div className="text-center navbar-brand-wrapper d-flex align-items-center justify-content-center">
        <div className="text-xl">Pixelro Admin</div>
      </div>
      <div className="navbar-menu-wrapper d-flex align-items-stretch justify-content-end">
        <button
          className="navbar-toggler navbar-toggler align-self-center"
          type="button"
          onClick={() => document.body.classList.toggle("sidebar-icon-only")}
        >
          <span className="ti-layout-grid2" />
        </button>

        <ul className="navbar-nav navbar-nav-right">
          {/* nenoons */}
          <li className="nav-item nav-profile">
            <a href="https://www.nenoons.com/" target="_blank" rel="noopener noreferrer">
              nenoons
            </a>
          </li>
          {/* 세팅, 로그아웃 */}
          <li className="nav-item nav-profile">
            <Dropdown alignRight>
              <Dropdown.Toggle className="nav-link count-indicator">
                <i className="fa fa-user" />
              </Dropdown.Toggle>
              <Dropdown.Menu className="preview-list navbar-dropdown">
                <Dropdown.Item className="dropdown-item preview-item" onClick={(evt) => evt.preventDefault()}>
                  <div className="d-flex align-items-center">
                    <i className="ti-power-off text-primary" />
                    <button className="pl-2" onClick={handleLogOut}>
                      LogOut
                    </button>
                  </div>
                </Dropdown.Item>
              </Dropdown.Menu>
            </Dropdown>
          </li>
        </ul>
        <button className="navbar-toggler navbar-toggler-right d-lg-none align-self-center" type="button" onClick={toggleOffcanvas}>
          <span className="ti-layout-grid2" />
        </button>
      </div>
    </nav>
  );
};

export default Navbar;
